import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/css/bootstrap.css';

import Router from './routers/router';

export default function App() {
  return (
    <>      
      < Router />
    </>    
  )
}
